<template>
  <div>
    <title>SIM INVENTORY ~ SAVE FINISH GOOD OUT</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Finish Good Out Data Lists
        <br />
        <h4>The following is a list of finish good out lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Finish Good Out Headers</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Out Number</label>
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="isidata.idfgout"
                    name=""
                    id=""
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.nomorpengeluaran"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Out Date</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="isidata.tglpengeluaran"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Customs Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.nomorkepabeanan"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Customs Date</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="isidata.tglkepabeanan"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">BC Type</label>
                  <select
                    class="form-control"
                    v-model="isidata.bctype"
                    id="bctype"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datatipebc in tipebc"
                      :key="datatipebc.id"
                      :value="datatipebc.value"
                    >
                      BC {{ datatipebc.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Invoice Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.nomorinvoice"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Finish Good Out Details</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer</label>
                   <select2
                    :data="kdbuyer"
                    :value="valuebuyer"
                    @update="updatebuyer($event)"
                  ></select2>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.jml"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Currency Unit</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.currunit"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.unit"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">FOB</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.fob"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.cmt"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Carton</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="isidata.karton"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </form>
          </div>
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Detail Data Ougoing Finish Good</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-2">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                      <th>Process Name</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Process Out Results</th>
                      <th>Buyer Code</th>
                      <th>Stock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>

                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>
                        {{ classdata.nomor_produksi }}
                      </td>
                      <td>{{ classdata.nomor_order }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.nama_proses }}</td>
                      <td>{{ classdata.tanggal_proses }}</td>
                      <td>{{ classdata.hasil_proses }}</td>
                      <td>{{ classdata.hasil_proses_out }}</td>
                      <td>{{ classdata.kode_buyer }}</td>
                      <td>{{ classdata.stok }}</td>
                      <td>
                        <button
                          @click="saveData(classdata)"
                          title="Save Finish Good Out"
                          class="btn btn-info"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                      <th>Process Name</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Process Out Results</th>
                      <th>Buyer Code</th>
                      <th>Stock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="11" class="text-center">
                          <b style="color: red"> {{ pesan }} </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      tipebc: [
        // { value: "25", id: 1 },
        // { value: "261", id: 2 },
        // { value: "27", id: 3 }
        { value: "30", id: 1 }
      ],
      isidata: {
        jml: "",
        currunit: "",
        unit: "",
        fob: "",
        cmt: "",
        karton: "",
        nomorpengeluaran: "",
        tglpengeluaran: "",
        nomorkepabeanan: "",
        tglkepabeanan: "",
        bctype: "",
        nomorinvoice: "",
        buyer: "",
        idfgout: ""
      },
      classdata: {
        id: ""
      },
      loading: false,
      isExist: true,
      namesearch: "",
      pesan: "",
      valuebuyer: "",
      list_paged: [],
      kdbuyer: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.showawal();
    this.getbuyerlist();
    this.getnow()
  },
  methods: {
    /* handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.updatebuyer();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.updatebuyer();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    }, */
    updatebuyer(value){
      this.valuebuyer = value;
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "fg_outgoing_d/getall_sumber_outgoing_datastok_hasil_produksi?length=" + this.pageSize + "&page=" + this.page + "&kode_buyer=" + this.valuebuyer
      console.log(urlAPIget)
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
            this.pesan = "... Data Not Found ...";
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglpengeluaran = tahun + "-" + month + "-" + tgl;
      this.isidata.tglkepabeanan = tahun + "-" + month + "-" + tgl;
    },
    getbuyerlist() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl + "buyer/getlistbuyer";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.kdbuyer = resp.data.data;
          this.kdbuyer.forEach((item) => {
            item.value = item.kode_buyer;
            item.label = item.name;
          });
          // this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    showawal() {
      this.isExist = false;
      this.pesan = "......";
    },
    saveData(classdata) {
      var np = this.isidata.nomorpengeluaran;
      var tp = this.isidata.tglpengeluaran;
      var nk = this.isidata.nomorkepabeanan;
      var tk = this.isidata.tglkepabeanan;
      var bt = this.isidata.bctype;
      var ni = this.isidata.nomorinvoice;
      var jm = this.isidata.jml;
      var cu = this.isidata.currunit;
      var u = this.isidata.unit;
      var fo = this.isidata.fob;
      var cm = this.isidata.cmt;
      var kt = this.isidata.karton;
      if (np == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (bt == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "BC Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ni == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Invoice Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatah = {
          nomor_pengeluaran: np,
          tanggal_pengeluaran: tp,
          kode_buyer: this.valuebuyer,
          nomor_kepabeanan: nk,
          kode_user: kodeuser,
          tanggal_kepabeanan: tk,
          jenis_kepabeanan: bt,
          no_invoice: ni
        };

        // console.log(paramdatah);

        let paramdatad = new FormData();

        paramdatad.append("nomor_pengeluaran", np);
        paramdatad.append("id_in", classdata.id);
        paramdatad.append("quantity", jm);
        paramdatad.append("kode_user", kodeuser);
        paramdatad.append("currency_unit", cu);
        paramdatad.append("unit", u);
        paramdatad.append("nilai_fob", fo);
        paramdatad.append("nilai_cmt", cm);
        paramdatad.append("jml_ctn", kt);

        /* const paramdatad = {
          nomor_pengeluaran: np,
          id_in: classdata.id,
          quantity: jm,
          kode_user: kodeuser,
          currency_unit: cu,
          unit: u,
          nilai_fob: fo,
          nilai_cmt: cm,
          jml_ctn: kt
        }; */

        // console.log(paramdatad);

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveDatah =
          this.$apiurl + "fg_outgoing_h/savefg_outgoing_h";
        const urlAPISaveDatad =
          this.$apiurl + "fg_outgoing_d/savefg_outgoing_d";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveDatah, paramdatah, { headers })
          .then((respo) => {
            console.log(respo);
            if (respo.data.status == true) {
              axios
                .post(urlAPISaveDatad, paramdatad, { headers })
                .then((resp) => {
                  console.log(resp);
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save detail data successfully",
                    showConfirmButton: false
                  });
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save header data successfully",
                    showConfirmButton: false
                  });
                  // this.$router.push({ name: "finishgoodout" });
                })
                .catch((erro) => {
                  console.log(erro);
                  this.loading = false;
                });
            }

            // console.log(resp);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    /* deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "fg_outgoing_h/hapusfg_outgoing_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    } */
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
